import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    hero,
    sectionCards,
    list,
    card,
    contact,
    sectionDescription,
    formDescription,
} from './contact.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IContactCard } from '../models/contact-card.model';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import SectionContact from '../components/organisms/section-contact';
import ContactCard from '../components/molecules/contact-card';
import SectionContactForm from '../components/organisms/section-contact-form';

interface IContactPageProps {
    readonly data: {
        allContactCard: IQueryAllResult<IContactCard>;
    };
}

const ContactPage: React.FC<IContactPageProps> = ({ data }) => {
    const { t } = useT();
    const { allContactCard } = data;
    const contactCards = getNodes(allContactCard);

    return (
        <MainLayout className={layout} showFloatingCircles={true}>
            <Hero
                className={hero}
                titleProps={{ children: t('contact.hero.title'), Tag: 'h1' }}
                color="yellow"
            />
            <Section
                className={sectionDescription}
                contentDisplay="center"
                circleDisplay="none"
                height="auto"
                theme="dark"
            >
                <h2 className={formDescription}>{t('contact.contact.form.description')}</h2>
            </Section>
            <SectionContactForm
                buttonProps={{
                    kind: 'normalDark',
                    color: 'pink',
                }}
            />
            {contactCards.length > 0 && (
                <Section
                    className={sectionCards}
                    circleDisplay="none"
                    contentDisplay="center"
                    theme="dark"
                    height="auto"
                >
                    <ul className={list}>
                        {contactCards.map((contactCard) => {
                            return (
                                <li key={`contact-item-${contactCard.contactCardId}`}>
                                    <ContactCard className={card} contactCard={contactCard} />
                                </li>
                            );
                        })}
                    </ul>
                </Section>
            )}
            <SectionContact className={contact} />
        </MainLayout>
    );
};

export const query = graphql`
    query ContactPage($locale: String!) {
        allContactCard(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...contactCardFields
                }
            }
        }
    }
`;

export default ContactPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
