import React from 'react';

import { container, header, item, link } from './contact-card.module.scss';
import { IContactCard } from '../../models/contact-card.model';
import { useT } from '../../hooks/use-translation';

import Text from '../atoms/text';

interface IContactCardProps {
    className?: string;
    contactCard: IContactCard;
    NameTag?: React.ElementType;
}

const ContactCard: React.FC<IContactCardProps> = ({
    className = '',
    contactCard,
    NameTag = 'h2',
}) => {
    const { t } = useT();
    const { email, name, phone, description } = contactCard;
    return (
        <div className={`${container} ${className}`}>
            <Text Tag={NameTag} className={header}>
                <span>{name}</span> <span>{description}</span>
            </Text>
            <Text Tag="div" className={item}>
                <p>{t('contact.card.phone.label')}</p>
                <a className={link} href={`tel:${phone}`}>
                    {phone}
                </a>
            </Text>
            <Text Tag="div" className={item}>
                <p>{t('contact.card.email.label')}</p>
                <a className={link} href={`mailto:${email}`}>
                    {email}
                </a>
            </Text>
        </div>
    );
};

export default ContactCard;
